@import './aos/ipad_3d';

.tractor[data-aos="slide-right"] {
  transform: translate3d(-100px, 0, 0);
}


.image-collection[data-aos="slide-left"] {
  transform: translate3d(200px, 0, 0);
}
