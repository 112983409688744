@import './variables';
@import '~bootstrap/scss/bootstrap';
@import "~trix/dist/trix";

@import './sales_site/typography';
@import './sales_site/transparency';
@import './sales_site/spacing';
@import './sales_site/shapes';
@import './sales_site/readability';
@import './sales_site/positioning';
@import './sales_site/navigation';
@import './sales_site/lists';
@import './sales_site/flash_messages';
@import './sales_site/buttons';
@import './sales_site/borders';
@import './sales_site/backgrounds';
@import './sales_site/tables';
@import './sales_site/quotes';
@import './sales_site/aos';
@import './sales_site/next_ui';

body {
  background-color: $white;
  color: $dark;
}

.shadow-md {
  box-shadow: 0px 2px 34px -18px rgba(0, 0, 0, 0.2), 0px 7px 11px -10px rgba(0, 0, 0, 0.2);
}

.grow-on-hover {
  transition: transform 0.7s ease;

  &:hover {
    transform: scale(1.025);
  }
}

form .inline-field .field_with_errors {
  display: inline-block;
}

.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}

@media(min-width: map-get($grid-breakpoints, md)) {
  .w-md-auto {
    width: auto !important;
  }
}
