.bg-primary-shimmer {
  background: linear-gradient(120deg, #3F9142 40%, #72b95c);
}

.btn.bg-primary-shimmer:hover {
  opacity: 0.9;
}

.bg-grey-100 {
  background-color: #F8F9FA;
}

.bg-grey-300 {
  background-color: #DEE2E6;
}


.bg-primary-light {
  background-color: desaturate(lighten($primary, 47%), 10%);
}

.bg-lighter {
  background-color: $lighter !important;
}

.bg-orange { background-color: $orange; }