blockquote q:before {
  content: '“';
}

blockquote q:after {
  content: '”';
}


blockquote q mark {
  background: linear-gradient(90deg, #FFEF0088 40%, #FFE57A88);
  color: #000;
  border-radius: 2px;
}

blockquote cite {
  opacity: 0.8;
  font-style: italic;
  padding-right: 5px;
}
