header .navbar {
  z-index: 100;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .navbar-toggler:active {
    background-color: $gray-400
  }
}

.navbar-brand img {
  height: 40px;
}

@include media-breakpoint-down(sm) {
  .navbar-collapse {
    padding: 0.5rem 1rem;
    z-index: 1000;
    margin: 1rem 0;
    height: auto!important;
    max-height: calc(100vh - 2rem);
    background-color: #fff;
    border-radius: .375rem;
    border: 1px solid #eee;
    // box-shadow: 0 1.5rem 4rem rgba(22,28,45,.15);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    transition: all .1s ease-in-out;
    transition-property: opacity,transform;
    transform-origin: top right;

    &.show {
      opacity: 1;
      transform: scale(1);
    }

    &.collapsing {
      opacity: 0;
      transform: scale(.9);
    }

    &.collapse:not(.show) {
      display: none;
    }
  }
}
