@include media-breakpoint-up(md) {
  .border-md-right {
    border-right: $border-width solid $border-color !important;
  }
}

.rounded-bottom-lg {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rounded-lg {
  border-radius: 10px !important;
}