
.serif {
  font-family: $font-family-serif;
}

.san-serif {
  font-family: $font-family-sans-serif;
}

.text-underline {
  text-decoration: underline;
}

.text-inherit {
  color: inherit !important;
}

.text-gray-400 {
  color: $gray-400;
}

.text-lighter {
  color: $lighter !important;
}

.text-light-1 {
  color: $light-1 !important;
}

.text-gray-100 {
  color: $gray-100;
}

.text-gray-200 {
  color: $gray-200;
}

.text-float {
  text-shadow: 1px 0px 60px rgba(52, 58, 64, 0.6) !important;
}

.font-weight-normal {
  font-weight: normal;
}

.display-5 {
  font-size: 2.5rem;
}

.display-6 {
  font-size: 1.5rem;
}

.display-7 {
  font-size: 1.3rem;
}

.display-8 {
  font-size: 1rem;
}

.display-8-5 {
  font-size: 0.9rem;
}

.display-9 {
  font-size: 0.8rem;
}

p {
  font-size: 1.1em;
  line-height: 1.5;
}


h1 {
  &.font-size-1 {
    font-size: 3rem;
  }
}

h2 {
  &.font-size-1 {
    font-size: 2.3rem;
  }
}

p {
  &.font-size-1 {
    font-size: 1.1rem;
  }
}

h1, h2, h3, h4, h5, h6, p, li, em, strong {
  text-rendering: optimizeLegibility;
}

h2 em {
  color: $primary;
  font-style: normal;
}

b, strong {
  font-weight: bold;
}

.blockquote {
  -webkit-font-smoothing: antialiased;

  cite {
    font-style: normal;
  }

  .blockquote-footer::before {
    content: none;
  }

  &.blockquote-serif p {
    font-family: $font-family-serif;
  }

  &.blockquote-primary p {
    border-left: solid 3px $primary;
    padding-left: .5rem;
    margin-bottom: 1rem;
  }

  &.blockquote-italic p {
    font-style: italic;
  }

  &.blockquote-card {
    background-color: $gray-100;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    padding: 1.6rem 1.6rem 0rem 1.6rem;
    position: relative;

    &::before, &::after {
      color: $gray-300;
      display: block;
      font-size: 52px;
      position: absolute;
      text-shadow: none;
    }
    &::before {
      content: "“";
      left: 5px;
      top: -5px;
    }
    &::after {
      content: "”";
      right: 5px;
      bottom: -30px;
    }
  }

  &.blockquote-light {
    position: relative;

    color: $dark;
    margin-bottom: 45px;
    margin-top: 45px;
    padding-bottom: 2em;
    padding-top: 2em;
    position: relative;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    p {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      color: $dark;
      font-style: italic;
      padding: 0 60px;
      z-index: 1;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        color: $gray-200;
        content: "\f10d";
        display: block;
        font-family: FontAwesome;
        font-size: 42px;
        left: 0px;
        position: absolute;
        text-shadow: none;
        top: -15px;
        z-index: -1;
      }

      &::after {
        bottom: -20px;
        color: $gray-200;
        content: "\f10e";
        display: block;
        font-family: FontAwesome;
        font-size: 42px;
        position: absolute;
        right: 0px;
        text-shadow: none;
        z-index: -1;
      }
    }

    .blockquote-footer {
      margin-top: 2rem;
      font-weight: bold;
      text-align: right;
    }
  }
}


@include media-breakpoint-up(md) {
  .text-up-md h1 {
    font-size: 3rem !important;
  }
}

.fs-1 {
  font-size: 14px;
}

.fs-2 {
  font-size: 16px;
}

@include media-breakpoint-down(xs) {
  .text-center-xs {
    text-align: center !important;
  }

  h1.text-xs-down {
    font-size: 2rem !important;
  }
  h2.text-xs-down {
    font-size: 1.8rem !important;
  }

  .text-xs-increase {
    font-size: 1.1rem !important;
  }

  .fs-1 {
    font-size: 16px;
  }

  .fs-2 {
    font-size: 18px;
  }
}
