.responsive-square {
  position: relative;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.shape-top {
  right: 0;
  top: 0;
  left: 0;
}

.shape-bottom {
  right: 0;
  bottom: 0;
  left: 0;

  & > * {
    transform-origin: top center;
  }
}

.shape {
  position: absolute;
  pointer-events: none;

  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  & > * {
    display: block;
  }
}

.shape-flip-v {
  transform: scale(-1, 1);
}


.shape:not([class*=shape-blur]) {
  overflow: hidden;

  & > * {
    transform: scale(2);
  }
}

.shape-fluid-x > * {
  width: 100%;
  height: auto;
}

.shape-blur-3 {
  top: 0;
  right: 0;
  left: 0;
}
