ul.list-style-primary {
  list-style: none; /* Remove default bullets */
}

ul.list-style-primary li::before {
  content: "\2022";
  color: $primary;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
