// For large blocks of text/articles

.readable {
  font-family: $font-family-serif;

  .trix-content > *:first-child {
    margin-top: 0 !important;
  }

  .blockquote {
    margin-bottom: 2rem;
  }

  .testimonial {
    position: relative;

    .quote {
      color: $dark;
      margin-bottom: 45px;
      margin-top: 45px;
      padding-bottom: 2em;
      padding-top: 2em;
      position: relative;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
    }

    p {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      color: $dark;
      font-style: italic;
      padding: 0 60px;
      z-index: 1;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        color: $gray-200;
        content: "\f10d";
        display: block;
        font-family: FontAwesome;
        font-size: 42px;
        left: 0px;
        position: absolute;
        text-shadow: none;
        top: -15px;
        z-index: -1;
      }

      &::after {
        bottom: -20px;
        color: $gray-200;
        content: "\f10e";
        display: block;
        font-family: FontAwesome;
        font-size: 42px;
        position: absolute;
        right: 0px;
        text-shadow: none;
        z-index: -1;
      }
    }

    .name {
      margin-top: 2rem;
      font-weight: bold;
      text-align: right;
    }
  }


  h1, h2, h3, h4, h5 {
    font-weight: bold;
    margin-top: 3rem;
    font-family: $font-family-sans-serif;
  }

  button, .btn {
    font-family: $font-family-sans-serif;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1.4rem;
    line-height: 1.5;
  }

  li {
    font-size: 1.1rem;
    margin-bottom: 0.6rem;
    line-height: 1.4;
  }

  ol {
    list-style-position: inside;
  }

  img {
    max-width: 100%;
  }
}
