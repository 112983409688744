
.img-skewed {
  perspective: 1200px;
  transform-style: preserve-3d;
}

.img-skewed-left {
  perspective-origin: left center;
}

.ipad-3d {
  border-radius: 7%;
  transform: scale(0.99);
}

.ipad-skewed-left-3d {
  border-radius: 5.3%;
  background: linear-gradient(#ffffff 1.26%, #898d91 8.4%, rgb(167, 167, 167) 16.39%, #7d847d 23.53%, #9d9d9d 95.8%, #f1f1f1);
  border-bottom-right-radius: 6%;
  box-shadow: 29px 46px 43px -27px rgba(80, 102, 144, 0.4), 16px 40px 75px -40px rgba(0, 0, 0, 0.2);
}

[data-aos=img-skewed-item-left].aos-animate {
  transform: rotateY(-30deg) rotateX(15deg) translate3d(0,0,0);
}

[data-aos=ipad-skewed-left-3d].aos-animate {
  transform: rotateY(-30deg) rotateX(15deg) translate3d(1px, -3px, -17px) scale(1.01);
}

[data-aos=img-skewed-item-left].aos-animate,
[data-aos=img-skewed-item-right].aos-animate,
[data-aos=ipad-skewed-left-3d].aos-animate {
  opacity: 1;
}
[data-aos=ipad-skewed-left-3d] {
  opacity: 0;
  transition: opacity 0.3s;
  transition-property: opacity,transform;
  will-change: opacity,transform,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

[data-aos=img-skewed-item-left],
[data-aos=img-skewed-item-right] {
  opacity: 0;
  transition-property: opacity,transform;
  will-change: opacity,transform,-webkit-transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
