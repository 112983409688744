// Fonts
$font-family-serif: Georgia, "Times New Roman", Times, serif; // For readability/large blocks of text
$font-family-sans-serif: "Lato", Arial, Helvetica, sans-serif; // For headings and supportive text

// Colors
$primary: #3f9142;
$success: lighten($primary, 5%);
$danger: #ca4750;
$light: #eef0f3;
$lighter: lighten($light, 3.8%);
$light-1: darken($light, 4%);
$blue: #3e93ff;
$orange: #F4801F;
$white:    #ffffff;
$gray-100: #f8f9fa;
$gray-150: #f1f2f5; // Custom color
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6a747f;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

// Buttons
$btn-disabled-opacity: .5;
$btn-padding-x: 1rem;
$select-option-padding-x: 1rem;

// Inputs
$input-bg: $gray-100;
$input-color: $gray-800;
$input-border-color: $gray-300;
$input-placeholder-color: $gray-500;

// Disabled Inputs
$input-disabled-bg: #ebeff3;
$input-disabled-border-color: #dee2e6;

// Focused Inputs
$input-focus-bg: #fff;
$input-focus-border-color: lighten($primary, 15%);
$input-focus-box-shadow:  none;

// Box Shadows
$box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1),16px 40px 75px -40px rgba(0,0,0,.2);
$box-shadow-lg: 14px 42px 46px -27px rgba(80, 102, 144, 0.4), 12px 34px 82px -40px rgba(0, 0, 0, 0.2);

// Misc
$border-radius: 4px;
$navbar-light-color: black;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
