
.jd-operations-center,
.next-ui {
  h1 {
    font-weight: 900;
    font-size: 36px;
  }

  h2 {
    font-weight: 900;
    font-size: 32px;
  }

  h3 {
    font-size: 26px;
    font-weight: 900;
  }

  h4 {
    font-size: 26px;
    font-weight: 400;
  }

  h5 {
    font-size: 21px;
    font-weight: 900;
  }

  .image-collection {
    font-size: 16px;
  }

  .video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    iframe {
      max-width: 600px;
      height: 300px;
      width: 100%;
      border: 0;
    }

    footer {
      padding-top: 0.25rem;
      opacity: 0.8;
      font-size: 14px;
    }
  }

  @media (max-width: 650px) {
    h1 {
      font-weight: 900;
      font-size: 28px;
    }

    h2 {
      font-weight: 900;
      font-size: 26px;
    }

    h3 {
      font-size: 18px;
      font-weight: 900;
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
    }

    h5 {
      font-size: 16px;
      font-weight: 900;
    }

    .image-collection {
      font-size: 12px;
    }

    .stack-on-sm {
      position: relative;
    }

    .stack-on-sm > *:nth-child(1) {
      margin-left: -62px;
      max-width: 90vw !important;
    }

    .stack-on-sm > *:nth-child(2) {
      position: absolute;
      box-shadow: -7px -2px 45px -10px #00000036;
      bottom: -50px;
      right: -30px;
      width: 60vw;
      min-width: 240px
    }
  }

  @media (min-width: 500px) and (max-width: 650px) {
    .stack-on-sm .d-lg-block {
      display: block !important;
    }

    .stack-on-sm .d-lg-none {
      display: none !important;
    }
  }
}
