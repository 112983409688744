
.btn-white {
  @include button-variant($white, $white, $hover-background: darken($white, 3%), $hover-border: darken($white, 3%), $active-background: darken($white, 5%), $active-border: darken($white, 7.5%));
}

.btn-float {
  transition: all 0.5s;
  &:hover {
    box-shadow: 3px 6px 16px -5px rgba($dark, .5) !important;
    transform: translate(0, -3px);
  }
}

.animate-icon-right-on-hover i {
  transition: transform 0.5s;
}

.animate-icon-right-on-hover:hover i {
  transform: translate(3px, 0);
}

.link-reset {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

@include media-breakpoint-down(xs) {
  .btn-xs-block {
    width: 100%;
    display: block;
  }
}
