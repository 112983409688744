
$alert-background-color:  rgba($red, 0.9);
$alert-font-color:        $white;
$notice-background-color: rgba($green, 0.9);
$notice-font-color:       $white;

@keyframes flash-up {
  0% {
    transform: translateZ(0) translate3d(0, 100%, 0);
  }

  5% {
    transform: translateZ(0) translate3d(0, 0, 0);
  }

  95% {
    transform: translateZ(0) translate3d(0, 0, 0);
  }

  100% {
    transform: translateZ(0) translate3d(0, 100%, 0);
  }
}

.flash-alert,
.flash-notice {
  animation: flash-up 6s 1;
  transform: translateZ(0) translate3d(0, 100%, 0);
  bottom: 0;
  font-size: 1.6em;
  left: 0;
  line-height: 1.2;
  padding: 0.6em 0.9em;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1000;
}

.flash-alert {
  background-color: $alert-background-color;
  color: $alert-font-color;
}

.flash-notice {
  background-color: $notice-background-color;
  color: $notice-font-color;
}
