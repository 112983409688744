.abs-top {
  top: 0;
}

.abs-bottom {
  bottom: 0;
}

.abs-right {
  right: 0;
}

.abs-left {
  left: 0;
}

.abs-y {
  top: 0;
  bottom: 0;
}

.abs-x {
  right: 0;
  left: 0;
}

.lower-1 {
  z-index: -1;
}

.higher-1 {
  z-index: 1;
}

.higher-10 {
  z-index: 10;
}

.higher-100 {
  z-index: 100;
}
