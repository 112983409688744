.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pt-10 {
  padding-top: 10rem;
}

.pb-10 {
  padding-bottom: 10rem;
}

.py-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.mt-n10 {
  margin-top: -10rem;
}

@each $number, $space in $spacers {
  .mt-n#{number} {
    margin-top: -$space;
  }
  .mb-n#{number} {
    margin-bottom: -$space;
  }
  .my-n#{number} {
    margin-top: -$space;
    margin-bottom: -$space;
  }
}

.mx--2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}


.mh-50vh {
  min-height: 50vh;
}


@include media-breakpoint-up(md) {
  .py-md-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
}

@include media-breakpoint-up(lg) {
  .py-lg-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
}


@include media-breakpoint-up(md) {
  .mw-md-100 {
    max-width: 100% !important;
  }
}
